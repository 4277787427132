/* Common styles */
.Navbar {
    height: 70px;
    overflow: visible;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:#fff6f6;
  }
  .logo{
  padding: 5px;
  margin-top: 3px;
  }
  
  
  .strong-text {
    font-weight: bolder;
    font-size: x-large;
    text-decoration: none;
    color: black;
  }
  
  .Navbar > .nav-logo {
    font-weight: 700;
    font-size: 21px;
    margin: 15px;
    color: white;
  }
  
  .open {
    z-index: 1;
  }
  
  .Navbar > .nav-items > a {
    color: rgb(0, 0, 0);
    font-size: 16px;
    text-decoration: none;
    margin: 15px;
    position: relative;
    opacity: 0.9;
  }
  
  .Navbar > .nav-items > a:hover {
    opacity: 1;
  }
  
  .Navbar > .nav-items > a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: rgb(0, 0, 0);
    transition: all 0.45s;
  }
  
  .Navbar > .nav-items > a:hover::before {
    width: 100%;
  }
  
  .Navbar > .nav-toggle {
    display: none;
  }
  
  /* Responsive styles */
  @media (max-width: 900px) {
    .Navbar > .nav-items {
      position: absolute;
      top: 60px;
      display: flex;
      flex-direction: column;
      background:#fff6f6;
      left: 0;
      width: 100%;
      height: auto;
      transform: translateX(-100%);
      transition: all 0.45s;
    }
  
    .Navbar > .nav-items > a::before {
      background: transparent;
    }
  
    .Navbar > .nav-items.open {
      transform: translateX(0);
    }
  
    .Navbar > .nav-toggle {
      display: flex;
      width: 50px;
      height: 50px;
      margin: 20px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
    .nav-toggle > .bar {
      position: relative;
      width: 32px;
      height: 2px;
      background: white;
      transition: all 0.45s ease-in-out;
    }
  
    .nav-toggle > .bar::before,
    .nav-toggle > .bar::after {
      content: "";
      position: absolute;
      height: 2px;
      background: white;
      border-radius: 2px;
      transition: all 0.45s ease-in-out;
      z-index: 1;
    }
  
    .nav-toggle > .bar::before {
      width: 25px;
      transform: translateY(-8px);
      right: 0;
    }
  
    .nav-toggle > .bar::after {
      width: 32px;
      transform: translateY(8px);
    }
  
    .nav-toggle.open > .bar {
      transform: translateX(-40px);
      background: transparent;
    }
  
    .nav-toggle.open > .bar::before {
      width: 32px;
      transform: rotate(45deg) translate(26px, -26px);
    }
  
    .nav-toggle.open > .bar::after {
      transform: rotate(-45deg) translate(26px, 26px);
    }
  }
  .nav-items{
    display: flex!important;
    justify-content: space-between;
  }
  a{
    text-decoration: none;
    color: white;
  }
  
  .nav-logo img{
    width: 70px;
    height: 70px;
  }